.page-footer {
  color: white;
  background-color: #000000;
  padding-top: 40px;
  padding-bottom: 10px;
}

.page-footer h4 {
  color: #333333;
  font-size: 11px;
  font-weight: bold;
}

.page-footer p {
  color: #ffffff;
  font-size: 11px;
}

.page-footer .footer-links {
  list-style: none;
  padding: 0;
  margin-bottom: 45px;
  word-break: break-all;
}

.page-footer .footer-links a {
  color: #555;
  font-size: 11px;
}

.page-footer .footer-links li {
  margin-bottom: 5px;
  line-height: 1.3;
}

.page-footer .footer-legal .copyright {
  margin-right: 60px;
}

.page-footer .footer-legal .legal-links .item {
  padding-right: 13px;
  border-right: 1px solid #ccc;
  margin: 0;
  margin-right: 13px;
}

.page-footer .footer-legal .legal-links .item:last-child {
  border-right: none;
}

.page-footer .footer-legal .legal-links .item h5 {
  font-size: 11px;
  margin-top: 0;
  margin-bottom: 0;
}

.page-footer .region {
  margin-bottom: 20px;
}

.page-footer .region a {
  color: var(--bs-body-bg);
  font-size: 11px;
}

.page-footer .region img {
  margin-right: 7px;
}

@media (min-width: 768px) {
  .page-footer .region {
    margin-bottom: 0;
  }
}

