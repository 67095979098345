
.footer {
  background: #000000;
}
.footer .container {
  padding-top: 30px;
  padding-bottom: 0px;
}
.footer .container .headline {
  color: rgb(145,145,145);
  margin-bottom: 15px;
}
.footer .container .item {
  font-size: 14px;
  margin-bottom: 10px;
}
.footer .container .item a {
  color: rgb(255,255,255);
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 14px;
}
.footer .container .logo-col img {
  width: 100%;
}

.footer .container .copyright p {
  color: rgb(132,132,132);
  font-size: 12px;
  text-align: center;
}

.footer-small {
  background: rgb(0,0,0);
  padding-top: 10px;
  padding-bottom: 30px;
  margin-top: 20px;

  font-size: 12px;
  color: rgb(255,255,255);
}

.footer-logo {
  display: flex;
  align-items: center;
}

.footer-mid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-media {
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.social-media ul {
  display: flex;
}
.social-media li {
  list-style: none;
}

.social-media i.fab{
  margin-right: 20px;
  font-size: 20px;
  color: rgb(255,255,255);
}
