.options {
    display: flex;
}

.option{
    padding-right: 10px;
}

.option-input {
    padding-right: 10px;
}